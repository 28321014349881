<!-- 用户编辑弹窗 -->
<template>
  <a-modal
    :width="580"
    :visible="visible"
    :confirm-loading="loading"
    :title="isUpdate ? '修改菜单' : '新建菜单'"
    :body-style="{ paddingBottom: '8px' }"
    @update:visible="updateVisible"
    @ok="save"
  >
    <a-form
      ref="form"
      :model="form"
      :rules="rules"
      :label-col="{ md: { span: 7 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 17 }, sm: { span: 24 } }"
    >
      <a-row :gutter="16">
        <a-col :md="20" :sm="24" :xs="24">
          <a-form-item label="appId:" name="appId">
            <a-input
              allow-clear
              placeholder="请输入appId"
              v-model:value="form.appId"
            />
          </a-form-item>
          <!-- <a-form-item label="团队:" name="appId">
            <a-select
              allow-clear
              placeholder="请选择团队"
              v-model:value="form.corpId"
            >
              <a-select-option
                v-for="item in corpList"
                :key="item.corpId"
                :value="item.corpId"
              >
                {{ item.corpName }}
              </a-select-option>
            </a-select>
          </a-form-item> -->
          <a-form-item label="名称:" name="name">
            <a-input
              allow-clear
              placeholder="请输入编号"
              v-model:value="form.name"
            />
          </a-form-item>
          <a-form-item label="分组:" name="groupCode">
            <a-select
              allow-clear
              placeholder="请选择分组"
              v-model:value="form.groupCode"
            >
              <a-select-option
                v-for="item in groupCodeDict"
                :key="item.dictDataCode"
                :value="item.dictDataCode"
              >
                {{ item.dictDataName }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="导航类型:" name="type">
            <a-select
              allow-clear
              placeholder="请选择导航类型"
              v-model:value="form.type"
            >
              <a-select-option
                v-for="item in typeDict"
                :key="item.dictDataCode"
                :value="item.dictDataCode"
              >
                {{ item.dictDataName }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <div v-if="form.type === '跳转小程序'">
            <a-form-item label="跳转小程序appId:" name="toAppId">
              <a-input
                allow-clear
                placeholder="请输入跳转小程序appId"
                v-model:value="form.toAppId"
              />
            </a-form-item>
          </div>
          <a-form-item label="导航角色:" name="rolesIds">
            <a-select
              v-model:value="form.rolesIdList"
              mode="multiple"
              style="width: 100%"
              placeholder="请选择公司"
              :options="
                rolesDict.map((item) => ({
                  value: item.dictDataName
                }))
              "
            >
            </a-select>
          </a-form-item>
          <a-form-item label="小程序路径:" name="url">
            <a-input
              allow-clear
              placeholder="请输入小程序路径"
              v-model:value="form.url"
            />
          </a-form-item>
          <a-form-item label="显示:" name="isShow">
            <a-select
              allow-clear
              placeholder="状态查询"
              v-model:value="form.isShow"
            >
              <a-select-option
                v-for="item in stateDict"
                :key="item.dictDataCode"
                :value="item.dictDataCode"
              >
                {{ item.dictDataName }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <!-- <a-form-item label="背景:" name="background">
            <a-input
              allow-clear
              placeholder="请输入背景"
              v-model:value="form.background"
            />
          </a-form-item> -->
          <a-form-item label="排序:" name="sortNumber">
            <a-input-number
              style="width: 100%"
              allow-clear
              placeholder="排序"
              v-model:value="form.sortNumber"
            />
          </a-form-item>
          <a-form-item label="路径图片:" name="img">
            <a-input
              allow-clear
              maxlength="30"
              placeholder="请选择图片"
              v-model:value="form.img"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <a-row type="flex" justify="center">
        <a-col>
          <a-upload
            list-type="picture-card"
            v-model:file-list="imgList"
            @preview="handlePreview"
            :customRequest="({ file }) => uploadFile(file, 'img')"
            :remove="removeFile"
          >
            <div v-if="imgList.length < 1">
              <plus-outlined />
              <div class="ant-upload-text">背景图</div>
            </div>
          </a-upload>
          <a-modal
            :visible="previewVisible"
            :footer="null"
            @cancel="handleCancel"
          >
            <img alt="example" style="width: 100%" :src="previewImage" />
          </a-modal>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
import setting from '@/config/setting'
import * as navApi from '@/api/sys/nav'
import * as corpApi from '@/api/corp/corp.js'
import * as dictDataApi from '@/api/sys/dictData'
// function getBase64(file) {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader()
//     reader.readAsDataURL(file)
//     reader.onload = () => resolve(reader.result)
//     reader.onerror = (error) => reject(error)
//   })
// }
export default {
  name: 'navigationEdit',
  emits: ['done', 'update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  data() {
    return {
      // 表单数据
      form: Object.assign({}, this.data),
      imgList: [],
      heros: [
        {
          name: '',
          idcard: ''
        }
      ],
      uploadUrl: setting.uploadUrl,
      // 表单验证规则
      rules: {
        name: [
          {
            required: true,
            message: '请输入名称',
            type: 'string',
            trigger: 'blur'
          }
        ]
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      previewVisible: false,
      previewImage: '',
      corpList: [],
      // 状态字典项
      stateDict: [],
      groupCodeDict: [],
      typeDict: [],
      rolesDict: [],
      selectValue: []
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.form = Object.assign({}, this.data)
        if (this.form.heros) {
          this.heros = JSON.parse(this.form.heros)
        }
        if (this.form.img) {
          this.imgList = [{ url: this.form.img }]
        }
        if (this.form.rolesIds) {
          this.form.rolesIdList = this.form.rolesIds.split(',')
        } else {
          this.form.rolesIdList = []
        }
        this.isUpdate = true
      } else {
        this.form = {
          rolesIdList: [],
          corpId: this.getCorpId()
        }
        this.isUpdate = false
      }
      if (this.$refs.form) {
        this.$refs.form.clearValidate()
      }
    }
  },
  mounted() {
    this.form.corpId = this.getCorpId()
    this.queryCorpList()
    this.queryDataStates()
    this.queryAppNavGroupCode()
    this.queryAppNavType()
    this.queryAppNavRole()
  },
  methods: {
    /* 保存编辑 */
    save() {
      if (this.form.rolesIdList) {
        this.form.rolesIds = this.form.rolesIdList.join(',')
      } else {
        this.form.rolesIdList = []
      }
      if (!this.isUpdate) {
        this.form.corpId = this.getCorpId()
      }
      this.$refs.form
        .validate()
        .then(() => {
          this.loading = true
          navApi
            .save(this.form, this.isUpdate)
            .then((res) => {
              this.loading = false
              if (res.code === 0) {
                console.log(res.code)
                this.$message.success(res.msg)
                if (!this.isUpdate) {
                  this.form = {
                    rolesIdList: []
                  }
                }
                this.updateVisible(false)
                this.$emit('done')
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        })
        .catch(() => {})
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    },
    // 删除图片
    removeFile(file) {
      console.log(file)
      this.$http
        .delete('/file/remove', {
          path: file.url
        })
        .then((res) => {
          if (res.data.code === 0) {
            this.form.img = ''
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
    // 上传图片
    uploadFile(file, name) {
      const formData = new FormData()
      formData.append('file', file)
      const hide = this.$message.loading('上传中..', 0)
      this.$http
        .post(this.uploadUrl, formData)
        .then((res) => {
          hide()
          if (res.data.code === 0) {
            if (name === 'img') {
              this.imgList[0] = {
                uid: '-4',
                name: 'image.png',
                status: 'done',
                url: res.data.location
              }
            }
            this.form[name] = res.data.location
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
      return false
    },
    handlePreview(file) {
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    // async handlePreview(file) {
    //   if (!file.url && !file.preview) {
    //     file.preview = await getBase64(file.originFileObj)
    //   }
    //   this.previewImage = file.url || file.preview
    //   this.previewVisible = true
    // },
    handleChange({ fileList }) {
      this.fileList = fileList
    },
    handleCancel() {
      this.previewVisible = false
    },
    // 获取团队信息
    queryCorpList() {
      corpApi
        .page({})
        .then((res) => {
          if (res.code === 0) {
            this.corpList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 状态字典项
    queryDataStates() {
      dictDataApi
        .querydictdata('isShow')
        .then((res) => {
          if (res.code === 0) {
            this.stateDict = res.data
            this.stateDict.map((item) => {
              item.dictDataCode = parseInt(item.dictDataCode)
            })
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 导航分组典项
    queryAppNavGroupCode() {
      dictDataApi
        .querydictdata('navGroup', this.form.corpId)
        .then((res) => {
          if (res.code === 0) {
            this.groupCodeDict = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 导航类型典项
    queryAppNavType() {
      dictDataApi
        .querydictdata('appNavType', this.form.corpId)
        .then((res) => {
          if (res.code === 0) {
            this.typeDict = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 导航角色字典项
    queryAppNavRole() {
      dictDataApi
        .querydictdata('appNavRole', this.form.corpId)
        .then((res) => {
          if (res.code === 0) {
            this.rolesDict = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    }
  }
}
</script>

<style scoped>
</style>
